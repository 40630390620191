export class BusinessLocation {
    constructor() {
        this.id = 0;
        this.name = "";
        this.latitude = 0;
        this.longitude = 0;
        this.description = "";
        this.address = "";
        this.comments = "";
    }
}
