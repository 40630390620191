export class JobExecutionLog {
    constructor() {
        this.id = "";
        this.hangfireId = "";
        this.jobName = "";
        this.completionPercentage = 0;
        this.status = 0;
        this.startDate = null;
        this.endDate = null;
    }
}
