import { DynamicEntityFieldType, DynamicEntityFieldValue, DynamicFieldDependecyType, QuantityFieldActionType, IntrinsicAssetCustomFieldID, IntrinsicInventoryItemCustomFieldID } from "eyam-webui-models";
import React, { useEffect, useState } from "react";
import { DateInput } from 'semantic-ui-calendar-react';
import moment from "moment";
import { Dropdown, Input, TextArea } from "semantic-ui-react";
import { DynamicDropdown } from "../DynamicDropdown/DynamicDropdown";
import { DynamicForm } from "../DynamicForm/DynamicForm";
import { FieldDependencyGraph } from "./FieldDependencyGraph";
export const DynamicEntityForm = (props) => {
    //CustomfieldID => DependsOnCustomFieldID => DependsOnLookupValueID => AllowedCustomFieldLookupValues
    const [filterCascadeDepMatrix, setFilterCascadeDepMatrix] = useState(new Map());
    const [dropdownLookupValues, setDropdownLookupValues] = useState(new Map());
    const [depGraph, setDepGraph] = useState();
    const handleFieldChange = (fieldID, type, value) => {
        if (props.onFieldChanged) {
            var newVal = new DynamicEntityFieldValue();
            newVal.setObjectValue(value, type, props.dateFormat != null ? props.dateFormat : "DD-MM-YYYY");
            props.onFieldChanged(fieldID, type, newVal);
            depGraph.triggerEffectsForField(fieldID, (crtfield, parentField, currentDependencies) => {
                var _a;
                console.debug(`DynamicEntityForm: triggerEffectsForField=> Field ${crtfield} | Name ${((_a = props.customFieldData) === null || _a === void 0 ? void 0 : _a.has(crtfield)) ? props.customFieldData.get(crtfield).customFieldName : "unknown"}`);
                if (currentDependencies.some(dp => dp.dependencyType == DynamicFieldDependecyType.FilterCascade)) {
                    if (props.valueMap.has(crtfield) && props.valueMap.get(crtfield).getObjectValue != null) {
                        var nullVal = new DynamicEntityFieldValue();
                        nullVal.setObjectValue(value, type);
                        props.onFieldChanged(crtfield, props.customFieldData.get(crtfield).fieldType, nullVal);
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                else {
                    return false;
                }
            });
        }
    };
    useEffect(() => {
        if (props.lookupData) {
            console.debug("DynamicEntityForm: Loading Lookup values");
            let result = new Map();
            props.lookupData.forEach((lkpData, key, map) => {
                let lkpTransfData = [];
                lkpData.forEach((lkpVal) => {
                    let cDropDownValue = { key: lkpVal.id, value: lkpVal.id };
                    cDropDownValue.text = props.translate ? props.translate(lkpVal.localizedKey) : lkpVal.name;
                    lkpTransfData.push({
                        dropdownProps: cDropDownValue,
                        lookupData: lkpVal
                    });
                    lkpTransfData.sort((a, b) => (a.dropdownProps.text > b.dropdownProps.text) ? 1 : -1);
                });
                result.set(key, lkpTransfData);
            });
            setDropdownLookupValues(result);
        }
    }, [props.lookupData]);
    useEffect(() => {
        //aggregate dependencies for fast access
        if (props.fieldDependencyData) {
            console.debug("DynamicEntityForm: aggregate dependency values");
            let depMatrix = new Map();
            if (props.fieldDependencyData) {
                let depGrps = new Map();
                for (let dp of props.fieldDependencyData) {
                    if (dp.dependencyType == DynamicFieldDependecyType.FilterCascade) {
                        if (!depGrps.has(dp.customFieldID)) {
                            depGrps.set(dp.customFieldID, []);
                        }
                        depGrps.get(dp.customFieldID).push(dp);
                    }
                }
                depGrps.forEach((group, key, map) => {
                    let dependencyFilterMap = new Map();
                    for (let dep of group) {
                        let dependencyGroups = new Map();
                        for (let lkpDep of dep.lookupDependencies) {
                            if (!dependencyGroups.has(lkpDep.dependsOnLookupValueID)) {
                                dependencyGroups.set(lkpDep.dependsOnLookupValueID, []);
                            }
                            dependencyGroups.get(lkpDep.dependsOnLookupValueID).push(lkpDep.lookupValueID);
                        }
                        dependencyFilterMap.set(dep.dependsOnCustomFieldID, dependencyGroups);
                    }
                    depMatrix.set(key, dependencyFilterMap);
                });
            }
            setFilterCascadeDepMatrix(depMatrix);
            let grp = new FieldDependencyGraph();
            grp.initialize(props.fieldDependencyData);
            setDepGraph(grp);
        }
    }, [props.fieldDependencyData]);
    const getFormGroups = () => {
        const groups = [
            {
                groupProps: {
                    grouped: false,
                    widths: 'equal',
                },
                formFields: []
            }
        ];
        if (props.viewAssociationData) {
            var sortedAsocs = props.viewAssociationData.sort((a, b) => a.displayOrder - b.displayOrder);
            for (let cAsoc of sortedAsocs) {
                let cfData = props.customFieldData.get(cAsoc.customFieldID);
                if (!cfData) {
                    console.debug("DynamicEntityForm: Custom field data for assoc not found: " + cAsoc.customFieldID);
                    continue;
                }
                let fieldConfig = {};
                if (props.errorMap.has(cfData.customFieldID)) {
                    fieldConfig.error = props.errorMap.get(cfData.customFieldID);
                }
                else {
                    fieldConfig.error = false;
                }
                fieldConfig.label = (props.translate ? props.translate(cfData.customFieldLocalizedKey) : cfData.customFieldName) +
                    (cAsoc.action === QuantityFieldActionType.UseIncrement ? " (+)" : cAsoc.action === QuantityFieldActionType.UseDecrement ? " (-)" : "");
                fieldConfig.disabled = cAsoc.isReadOnly;
                fieldConfig.required = cAsoc.isRequired;
                let fldValue = null;
                if (props.valueMap.has(cfData.customFieldID) && props.valueMap.get(cfData.customFieldID)) {
                    fldValue = props.valueMap.get(cfData.customFieldID).getObjectValue(cfData.fieldType);
                }
                if (cfData.fieldType == DynamicEntityFieldType.Lookup &&
                    (cfData.customFieldID == IntrinsicAssetCustomFieldID.LocationID ||
                        cfData.customFieldID == IntrinsicInventoryItemCustomFieldID.LocationID ||
                        cfData.customFieldID == IntrinsicAssetCustomFieldID.SKU ||
                        cfData.customFieldID == IntrinsicInventoryItemCustomFieldID.SKU)) {
                    fieldConfig.control = DynamicDropdown;
                    let lkpCfg = {
                        onChange: (_, data) => { handleFieldChange(cfData.customFieldID, cfData.fieldType, data.value); },
                        options: getDropDownItemsFromLookup(cfData.customFieldID, cAsoc.metadata),
                        fluid: true,
                        selection: true,
                        value: fldValue ? fldValue : "",
                        search: true,
                        maxDisplayed: 7,
                    };
                    fieldConfig = Object.assign(Object.assign({}, fieldConfig), lkpCfg);
                }
                else {
                    switch (cfData.fieldType) {
                        case DynamicEntityFieldType.String:
                            fieldConfig.control = Input;
                            let inputCfg = {
                                onChange: (_, data) => { handleFieldChange(cfData.customFieldID, cfData.fieldType, data.value); },
                                value: fldValue ? fldValue : ""
                            };
                            fieldConfig = Object.assign(Object.assign({}, fieldConfig), inputCfg);
                            break;
                        case DynamicEntityFieldType.Integer:
                        case DynamicEntityFieldType.Float:
                        case DynamicEntityFieldType.Long:
                            fieldConfig.control = Input;
                            let intInputCfg = {
                                onChange: (_, data) => { handleFieldChange(cfData.customFieldID, cfData.fieldType, data.value); },
                                type: "number",
                                value: fldValue ? fldValue : ""
                            };
                            fieldConfig = Object.assign(Object.assign({}, fieldConfig), intInputCfg);
                            break;
                        case DynamicEntityFieldType.Boolean:
                            fieldConfig.control = Dropdown;
                            let boolLkpCfg = {
                                onChange: (_, data) => { handleFieldChange(cfData.customFieldID, cfData.fieldType, data.value); },
                                fluid: true,
                                selection: true,
                                options: [{ key: 1, value: true, text: props.translate ? props.translate("GENERIC-LABEL-YES") : "Yes" }, { key: 2, value: false, text: (props.translate ? props.translate("GENERIC-LABEL-NO") : "No") }],
                                value: fldValue ? fldValue : "",
                                search: true
                            };
                            fieldConfig = Object.assign(Object.assign({}, fieldConfig), boolLkpCfg);
                            break;
                        case DynamicEntityFieldType.Date:
                            fieldConfig.control = DateInput;
                            let datePkrProps = {
                                onChange: (_, data) => { handleFieldChange(cfData.customFieldID, cfData.fieldType, data.value); },
                                error: props.errorMap.has(cfData.customFieldID) ? props.errorMap.get(cfData.customFieldID) : !checkDateFieldFormat(fldValue),
                                readOnly: cAsoc.isReadOnly,
                                inline: false,
                                value: fldValue ? fldValue : "",
                                dateFormat: props.dateFormat != null ? props.dateFormat : "DD-MM-YYYY"
                            };
                            fieldConfig = Object.assign(Object.assign({}, fieldConfig), datePkrProps);
                            break;
                        case DynamicEntityFieldType.Lookup:
                            fieldConfig.control = Dropdown;
                            let lkpCfg = {
                                onChange: (_, data) => { handleFieldChange(cfData.customFieldID, cfData.fieldType, data.value); },
                                options: getDropDownItemsFromLookup(cfData.customFieldID, cAsoc.metadata),
                                fluid: true,
                                selection: true,
                                value: fldValue ? fldValue : "",
                                search: true
                            };
                            fieldConfig = Object.assign(Object.assign({}, fieldConfig), lkpCfg);
                            break;
                        case DynamicEntityFieldType.LongString:
                            fieldConfig.control = TextArea;
                            let txtAreaCfg = {
                                onChange: (_, data) => { handleFieldChange(cfData.customFieldID, cfData.fieldType, data.value); },
                                value: fldValue ? fldValue : ""
                            };
                            fieldConfig = Object.assign(Object.assign({}, fieldConfig), txtAreaCfg);
                            break;
                        case DynamicEntityFieldType.Array:
                            fieldConfig.control = Dropdown;
                            let multiLkpCfg = {
                                onChange: (_, data) => { handleFieldChange(cfData.customFieldID, cfData.fieldType, data.value); },
                                search: true,
                                multiple: true,
                                options: getDropDownItemsFromLookup(cfData.customFieldID, cAsoc.metadata),
                                selection: true,
                                value: fldValue ? fldValue : []
                            };
                            fieldConfig = Object.assign(Object.assign({}, fieldConfig), multiLkpCfg);
                            break;
                    }
                }
                if (props.metadataFieldTransform) {
                    props.metadataFieldTransform(cfData.metadata, cAsoc.metadata, fieldConfig);
                }
                groups[0].formFields.push(fieldConfig);
            }
        }
        return groups;
    };
    const checkDateFieldFormat = (dateValue) => {
        let dateFormat = props.dateFormat != null ? props.dateFormat : "DD-MM-YYYY";
        if ((dateValue === null || dateValue === void 0 ? void 0 : dateValue.length) > 0 && !moment(dateValue, dateFormat, true).isValid()) {
            return false;
        }
        return true;
    };
    const getDropDownItemsFromLookup = (fieldID, associationMetaData) => {
        //TODO: maybe put these values on soem state obejct so they don;t get affect performance
        let result = [];
        if (!props.customFieldData.has(fieldID)) {
            console.debug("DynamicEntityForm: field not defined: " + fieldID);
            return [];
        }
        let cfData = props.customFieldData.get(fieldID);
        if (!props.lookupData.has(cfData.lookupTableID)) {
            console.debug("DynamicEntityForm: lookup data not defined: " + cfData.lookupTableID);
            return [];
        }
        if (!dropdownLookupValues.has(cfData.lookupTableID)) {
            console.debug("DynamicEntityForm: lookup data not transformed: " + cfData.lookupTableID);
            return [];
        }
        dropdownLookupValues.get(cfData.lookupTableID).forEach((lkpVal, lkpTblID) => {
            let cDropDownValue = { key: lkpVal.dropdownProps.key, value: lkpVal.dropdownProps.value, text: lkpVal.dropdownProps.text };
            if (props.metadataLkpValueTransform) {
                props.metadataLkpValueTransform(lkpVal.lookupData, associationMetaData, cDropDownValue);
            }
            //props.valueMap.has(cfData.customFieldID) ? props.valueMap.get(cfData.customFieldID).getObjectValue(cfData.fieldType) : []
            let lkpValAvailable = true;
            //CustomfieldID => DependsOnCustomFieldID => DependsOnLookupValueID => AllowedCustomFieldLookupValues
            if (filterCascadeDepMatrix.has(fieldID)) {
                let crtFilters = filterCascadeDepMatrix.get(fieldID);
                let allowedValues = null;
                for (let [depCfID, depFilter] of crtFilters) {
                    //if no vale is selected for depending field we can;t have valeu for the current one
                    if (!props.valueMap.has(depCfID)) {
                        lkpValAvailable = false;
                        break;
                    }
                    let compValues = [];
                    //let cValue = props.valueMap.get(depCfID).getObjectValue(cfData.fieldType);
                    let cValue = props.valueMap.get(depCfID).getObjectValue(props.customFieldData.get(depCfID).fieldType);
                    if (cValue) {
                        if (props.customFieldData.get(depCfID).fieldType == DynamicEntityFieldType.Array) {
                            compValues = [...cValue];
                        }
                        else if (cValue != "") {
                            compValues.push(cValue);
                        }
                    }
                    let allowedValsForDepLkp = [];
                    for (let cDepLkpVal of compValues) {
                        if (depFilter.has(cDepLkpVal)) {
                            depFilter.get(cDepLkpVal).forEach(cDepVal => {
                                if (!allowedValsForDepLkp.some(v => v == cDepVal)) {
                                    allowedValsForDepLkp.push(cDepVal);
                                }
                            });
                        }
                    }
                    if (allowedValues == null) {
                        allowedValues = allowedValsForDepLkp;
                    }
                    else {
                        allowedValues = allowedValues.filter(x => allowedValsForDepLkp.includes(x));
                    }
                }
                if (allowedValues == null || !allowedValues.includes(lkpVal.lookupData.id)) {
                    lkpValAvailable = false;
                }
            }
            if (lkpValAvailable) {
                result.push(cDropDownValue);
            }
        });
        return result;
    };
    return (React.createElement("div", { className: "dynamic-form-container" },
        React.createElement(DynamicForm, { groups: getFormGroups() })));
};
