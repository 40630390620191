import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, Label } from "semantic-ui-react";
export const GPSCaptureControl = (props) => {
    const [latitude, setLatitude] = useState(props.defaultLatValue || 0);
    const [longitude, setLongitude] = useState(props.defaultLongValue || 0);
    const { t, i18n } = useTranslation();
    useEffect(() => {
        props.onLatitudeChange(latitude);
    }, [latitude]);
    useEffect(() => {
        props.onLongitudeChange(longitude);
    }, [longitude]);
    const onRefreshCoords = () => {
        navigator.geolocation.getCurrentPosition((location) => {
            const { latitude, longitude } = location.coords;
            setLatitude((latitude <= 90 && latitude >= -90) ? latitude : 0);
            setLongitude((longitude <= 180 && longitude >= -180) ? longitude : 0);
        });
    };
    const validateField = (fieldName) => {
        let error = null;
        if (fieldName === "latitude" && (latitude < -90 || latitude > 90)) {
            error = { content: t("PAGE_LABEL-PAGE_ASSET_FIELD_VALIDATION_LATITUDE"), pointing: "above", color: "red", basic: true };
        }
        else if (fieldName === "longitude" && (longitude < -180 || longitude > 180)) {
            error = { content: t("PAGE_LABEL-PAGE_ASSET_FIELD_VALIDATION_LONGITUDE"), pointing: "above", color: "red", basic: true };
        }
        if (error && !props.isReadonly) {
            return React.createElement(Label, Object.assign({}, error));
        }
    };
    return (React.createElement(Form, null,
        React.createElement(Form.Group, null,
            React.createElement(Form.Field, { className: "gps-form-field" },
                React.createElement("label", null, "Latitude"),
                React.createElement(Input, { type: "number", step: "any", value: latitude, disabled: props.isReadonly, onChange: (event) => setLatitude(+event.target.value) }),
                validateField("latitude")),
            React.createElement(Form.Field, { className: "gps-form-field" },
                React.createElement("label", null, "Longitude"),
                React.createElement(Input, { type: "number", step: "any", value: longitude, disabled: props.isReadonly, onChange: (event) => setLongitude(+event.target.value) }),
                validateField("longitude")),
            !props.isReadonly && (React.createElement("div", { style: { alignSelf: "end" }, className: "field" },
                React.createElement(Button, { className: "gps-capture-control-button", icon: "dot circle outline", onClick: () => onRefreshCoords() }))))));
};
